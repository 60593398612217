.showcase-start {
    height: 90vh;
    position: relative;
}

.showcase-start:before {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.showcase-start .showcase-start-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

h1:hover{
    cursor: default;
}

.btn{
    display: inline-block;
    padding: 0.75rem, 1.5rem;
    margin-top: 1rem;
}