@import url(https://fonts.googleapis.com/css?family=Open+Sans|Playfair+Display+SC);
.App {
  padding: 1em;
}

label {
  display: inline-block;
  width: 100px;
}

header {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

/* typography */

html {
  font-family: 'helvetica neue', sans-serif;
}

thead th, tfoot th {
  font-family: 'Rock Salt';
}

th {
  letter-spacing: 2px;
  padding: 10px;
}

td {
  letter-spacing: 1px;
  overflow-x:hidden;
  overflow-y:hidden;
  font-size: 12px;
  padding: 10px;
  text-align: center;
}

header > h1 {
  text-align: center;
  font-family: 'Playfair Display SC';
  font-size: 64px;
}

/* table */

table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  overflow:hidden;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.content_td p {
  max-width: 100%;
  max-height: 100px;
  overflow-y: auto;
  text-overflow: ellipsis;
}

/* Column Size for thead and tbody */
 th, td {
  width: 10%
} 

tr :nth-child(4){
  width: 30%;
}

tr :nth-child(5){
  width: 30%
}  

/* Zebra Pattern */

tbody tr:nth-child(odd){ 
  background-color: #fff0c7;
}

/* Image Scaling */
img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}

input[name=name], input[name=cuisine]{
  max-width: 100%;
}

input[name=photo]{
  max-width: 85%;
}
tr button{
  margin: 0 2px;
}
tr button[name=delete]:hover{
  background-color: rgb(235, 151, 151);
  opacity: 40%;
}
tr button[name=edit]:hover{
  background-color: #c3e1df;
}

.creatorForm td{
  width: 10%;
}
.creatorForm td:nth-child(4){
  width: 30%;
}
.creatorForm td:nth-child(5){
  width: 30%;
}
.myLogo{
    display: flex;
    width: 10%;
    transition: all 0.5s;
}

.myLogo:hover{
    opacity: 70%;
}
.showcase {
    background-color: rgba(186,130,130,0.75);
    color: #fff;
    height: 90vh;
    position: relative;
}

.showcase:before {
    content:"";
    background: url(/static/media/homePage.4f8533bf.png) no-repeat center center/cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.showcase .showcase-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

h1:hover{
    cursor: default;
}

.btn{
    display: inline-block;
    padding: 0.75rem, 1.5rem;
    margin-top: 1rem;
}
.showcase-start {
    height: 90vh;
    position: relative;
}

.showcase-start:before {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.showcase-start .showcase-start-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
}

h1:hover{
    cursor: default;
}

.btn{
    display: inline-block;
    padding: 0.75rem, 1.5rem;
    margin-top: 1rem;
}
.loadingImage {
    min-width: 50px;
    max-width: 500px;
}
